import { useState } from 'react';
import './App.css';
import { OnymosGoogleLogin, useOnymosGoogleLogout, useAuth } from '@onymos/react-access';
// import OnymosButton from './OnymosButtonBackup';

function App() {
  const [userInfo, setUserInfo] = useState(useAuth())
  const auth = useAuth();
  const logout = useOnymosGoogleLogout()
  const handleLogout = () => {
    logout()
  }
  const handleLogin = (res) => {
    console.log("response from callback:", res)
    setUserInfo(res)
  }
  
  return (
    <div className="App">
      {auth ?
      <div className="App-header">
        <p>User is logged in, click the button to logout</p>
        <button onClick={handleLogout}>log out of google</button>
      </div> :
      <div className="App-header">
        <p>No user is logged in, click the button to sign in with google</p>
        <OnymosGoogleLogin 
          callback={handleLogin} 
          enableOneTap={true} 
          auto_select={true}/>
        {/* <OnymosButton /> */}
      </div>
      }
      <div className="Sections">
        <div className="Section"> 
          <h4>User auth info from useAuth():</h4>
          <pre>{JSON.stringify(auth, null, 2)}</pre>
        </div>
        <div className="Section"> 
          <h4>User state info (updated from callback):</h4>
          <pre>{JSON.stringify(userInfo, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
}

export default App;
